<template>
  <div class="page-login bg-cover">
    <router-link class="logo" to="/">
      <img src="@/assets/images/logo.png">
    </router-link>
    <div class="text s56 font-zihun color-f">
      <p>选择易筑</p>
      <p>让工程建筑更容易</p>
    </div>
    <div class="md-login c-card">
      <div class="tabs flex-center s18 color-2 font-medium">
        <div class="tab-item" :class="{ on: loginType == 1 }" @click="changeLoginType(1)">密码登录</div>
        <div class="tab-item" :class="{ on: loginType == 2 }" @click="changeLoginType(2)">短信登录</div>
        <div class="tab-item" :class="{ on: loginType == 3 }" @click="changeLoginType(3)">扫码登录</div>   
      </div>
      <form class="form s16 color-9">
        <!-- 密码 -->
        <template v-if='loginType == 1'>
          <!-- 账号 -->
          <div class="form-item form-item--ipt">
            <div class="text_input">
              <img src="@/assets/images/login-acount.png" alt="" class="ipt-icon">
              <input type="text" class="ipt" placeholder="请输入登录账号" v-model="passwordForm.username">
            </div>
            <div class="text_tip" v-if="showTextTip">请输入登录账号</div>
          </div>

          <!-- 密码 -->
          <div class="form-item form-item--ipt">
            <div class="password_input">
              <img src="@/assets/images/login-psd.png" alt="" class="ipt-icon">
              <input type="password" class="ipt" placeholder="请输入登录密码" v-model="passwordForm.password">
            </div>
            <div class="text_tip" v-if="showPasswordTip">请输入登录密码</div>
          </div>

          <!-- 记住密码 -->
          <!-- <div class="form-item form-item--1">
            <BaseSwitch v-model="remember">记住密码</BaseSwitch>
          </div> -->
        </template>
        <!-- 短信 -->
        <template v-if='loginType == 2'>
          <!-- 手机号 -->
          <div class="form-item form-item--ipt">
            <div class="text_input">
              <!-- <BaseSelect :options='options' ipt-class='area-code'/> -->
              <img src="@/assets/images/login-acount.png" alt="" class="ipt-icon">
              <input type="text" class="ipt" placeholder="请输入手机号" v-model="sendForm.username">
            </div>
            <div class="text_tip" v-if="showTextTip">请输入手机号</div>
          </div>
          <!-- 验证码 -->
          <div class="form-item form-item--ipt form-item--code">
            <div class="password_input">
              <img src="@/assets/images/login-valid.png" alt="" class="ipt-icon">
              <input type="text" class="ipt" placeholder="请输入验证码" v-model="sendForm.code">
              <BaseButton style-type='light' class="btn-code" @click.native="sendCode">{{ sendCodeBtnText }}</BaseButton>
            </div>
            <div class="text_tip" v-if="showPasswordTip">请输入验证码</div>
          </div>
        </template>
        <!-- 扫码 -->
        <template v-if='loginType == 3'> 
          <div v-if="!isExpire">
            <div class="qr_box" v-if="qrUrl">
              <img :src="qrUrl">
            </div>
            <div class="qr_txt" style="text-align: center;">请使用“易筑-APP”扫描二维码</div>
          </div>
          <!-- 过期 -->
          <div v-if="isExpire" class="qr_box" @click="expireClick">
            <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg51.png">
          </div>
        </template>
        <!-- 登录 -->
        <div class="form-item">
          <BaseButton size='large' class="btn-submit" tag='div' @click="handleSubmit">登录</BaseButton>
        </div>
        <!-- 同意协议 -->
        <div class="form-item form-item--2">
          <!-- /agreement -->
          <BaseSwitch v-model="agree" v-if='loginType < 3'>
            已阅读并同意
            <span @click="jumpAgreement">《用户协议》</span>
            <span @click="jumpAgreement">《隐私政策》</span>
          </BaseSwitch>
        </div>
      </form>
      <div class="link s16 color-9">
        <!-- <router-link to="" class="link-1 color-green flex-middle">
          <img src="@/assets/images/icon-wechat4.png" alt="">
          <span>微信扫码登录</span>
        </router-link> -->
        <router-link to="/register" class="link-2">新用户注册</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { passwordLoginApi, sendLoginApi, sendSmsApi, ProtocolsApi, QrcodeApi, QrcodeLoginApi } from "@/request/api/login.js";
export default {
  data() {
    return {
      loginType: 1,
      remember: false,
      agree: false,
      areaCode: 86,
      options: [
        { value: 86, name: '+86' },
        { value: 886, name: '+886' },
        { value: 852, name: '+852' },
        { value: 853, name: '+853' },
      ],
      sendCodeBtnText: '发送验证码',
      isSendCode: false,
      timer: '',
      showPasswordTip: false,
      showTextTip: false,

      passwordForm: {
        username: "",
        password: ""
      },
      sendForm: {
        username: "",
        code: ""
      },
      qrUrl: '',
      keyer: null,
      isExpire: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.keyer);
  },
  watch: {
    "passwordForm.username"(val) {
      if (val) {
        this.showTextTip = false;
      }
    },
    "passwordForm.password"(val) {
      if (val) {
        this.showPasswordTip = false;
      }
    },
    "sendForm.username"(val) {
      if (val) {
        this.showTextTip = false;
      }
    },
    "sendForm.code"(val) {
      if (val) {
        this.showPasswordTip = false;
      }
    }
  },
  methods: {
    jumpAgreement() {
      ProtocolsApi().then(({ status, data }) => {
        if (status == 200) {
          window.open(data.reg, '_blank')
        }
      })
    },
    changeLoginType(type) {
      this.agree = false;
      this.loginType = type;
      this.showPasswordTip = false;
      this.showTextTip = false;
      if (this.loginType == 1) {
        clearInterval(this.keyer);
        this.passwordForm = {
          username: "",
          password: ""
        }
      } else if (this.loginType == 2) {
        clearInterval(this.keyer);
        this.sendForm = {
          username: "",
          code: ""
        }
        // 扫码登录
      } else if (this.loginType == 3) {
        this.getQrcodeFn();
      }
    },
    // 获取二维码
    getQrcodeFn() {
      QrcodeApi().then(({ status, data }) => {
        if (status == 200) {
          this.qrUrl = data.url;
          this.keyer = setInterval(() => {
            QrcodeLoginApi({ value: data.value }).then(res => {
              if (res.status == 200) {
                // 过期
                if (res.data.qrcode_status == -1) {
                  this.isExpire = true;
                  clearInterval(this.keyer);

                }
                // 扫码成功
                if (res.data.qrcode_status == 1) {
                  this.isExpire = false;
                  clearInterval(this.keyer);
                  localStorage.setItem("token", res.data.token)
                  this.$router.push("/");
                }
                // 等待扫码
                if (res.data.qrcode_status == 0) {
                  this.isExpire = false;
                }
              }
            })
          }, 1000)
        }
      })
    },
    // 刷新二维码
    expireClick() {
      this.getQrcodeFn();
    },
    //校验
    validate() {
      if (this.loginType == 1) {
        if (!this.passwordForm.username && !this.passwordForm.password) {
          this.showTextTip = true;
          this.showPasswordTip = true;
          return false
        }
        if (!this.passwordForm.username) {
          this.showTextTip = true;
          return false
        }
        if (!this.passwordForm.password) {
          this.showPasswordTip = true;
          return false
        }
      } else {
        if (!this.sendForm.username && !this.sendForm.code) {
          this.showTextTip = true;
          this.showPasswordTip = true;
          return false
        }

        if (!this.sendForm.username) {
          this.showTextTip = true;
          return false
        }
        if (!this.sendForm.code) {
          this.showPasswordTip = true;
          return false
        }
      }

      if (!this.agree) {
        this.$message.warning("请勾选协议");
        return false
      }
      return true
    },
    //密码登录
    passwordLogin() {
      let params = { ...this.passwordForm };
      passwordLoginApi(params).then(res => {
        if (res.status == 200) {
          localStorage.setItem("token", res.data.token)
          this.$router.push("/");
        }
      })
    },
    //短信登录
    sendLogin() {
      let params = {
        ...this.sendForm
      }
      sendLoginApi(params).then(res => {
        if (res.status == 200) {
          localStorage.setItem("token", res.data.token)
          this.$router.push("/");
        }
      })
    },
    //登录
    handleSubmit() {
      if (!this.validate()) return;
      if (this.loginType == 1) {
        this.passwordLogin();
      } else {
        this.sendLogin();
      }
    },
    sendCode() {
      if (!this.sendForm.username) {
        this.showTextTip = true;
        return
      }
      if (!this.agree) {
        this.$message.warning("请勾选协议");
        return false
      }
      let params = {
        type: 1,
        mobile: this.sendForm.username
      }
      sendSmsApi(params).then(res => {
        if (res.status == 200) {
          this.$message.success("验证码已发送");
          var time = 60
          this.timer = setInterval(() => {
            if (time == 0) {
              clearInterval(this.timer)
              this.sendCodeBtnText = '发送验证码'
            } else {
              this.sendCodeBtnText = time + '秒后重试'
              time--;
            }
          }, 1000)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-login {
  min-height: 100vh;
  background-image: url(~@/assets/images/login-bg.jpg);
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.6rem;

  .logo {
    position: absolute;
    left: 1.6rem;
    top: .18rem;
    font-size: 0;

    img {
      width: 1.1rem;
    }
  }

  .text {
    position: absolute;
    left: 1.6rem;
    top: 45.8%;
    transform: translateY(-50%);
    line-height: calc(76 / 56 * 1em);
    text-shadow: 2px 2px 2px rgba($color: #00c6ed, $alpha: .1);
  }
}

.md-login {
  width: 5.33rem;
  margin-left: auto;
  padding: .78rem .59rem .64rem;
  background: rgba($color: #fff, $alpha: .95);

  .tabs {
    .tab-item {
      margin-right: .2rem;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        display: block;
        content: '';
        width: .2rem;
        height: .04rem;
        background-color: $blue;
        border-radius: .02rem;
        margin: .06rem auto 0;
        opacity: 0;
      }

      &.on {
        color: $blue;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .form {
    margin-top: .3rem;

    .ipt-icon {
      width: .2rem;
    }

    .ipt {
      width: 100%;
      height: .5rem;
      background-color: #ffffff;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      color: #222;
      transition: .2s;
      padding: 0 .1rem;

      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: $blue;
      }
    }

    &-item {
      margin-bottom: .2rem;
      width: 100%;

      &--ipt {
        position: relative;

        .ipt-icon {
          position: absolute;
          left: .11rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        .ipt {
          padding-left: .36rem;
        }
      }

      .text_input,
      .password_input {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
      }

      .text_tip {
        color: red;
      }

      &--1 {
        margin-top: -.02rem;
        margin-bottom: .17rem;
      }

      &--2 {
        margin-top: -.04rem;

        a:hover {
          color: $blue;
        }
      }

      &--phone {

        // display: flex;
        ::v-deep .area-code {
          height: .5rem;
          width: 0.7rem;
          background-color: #fff;
          border-radius: .04rem 0 0 .04rem;
          border: solid 1px #cae1ec;
          border-right: none;
          color: #444;
        }

        .ipt {
          border-radius: 0 .04rem .04rem 0;
        }
      }

      &--code {
        .ipt {
          padding-right: 1.2rem;
        }

        .btn-code {
          position: absolute;
          right: .04rem;
          top: 0.04rem;
          bottom: .04rem;
          margin: auto;
          min-width: 1.1rem
        }
      }
    }

    .btn-submit {
      width: 100%;
    }
  }

  .link {
    display: flex;
    justify-content: flex-end;
    margin-top: .36rem;

    &-1 {
      img {
        width: .2rem;
        margin-right: .06rem;
      }
    }

    &-2 {
      &:hover {
        color: $green;
      }
    }
  }
}

.qr_box {
  display: flex;
  justify-content: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>